import React from 'react';

export default function Logo() {
    return (
        <svg height="24px" fill="currentColor" viewBox="0 0 569.455 569.456">
    <g>
        <path d="M475.872,173.878c-3.06-58.14-11.934-116.586-30.6-169.218c-2.448-7.038-12.546-5.508-13.464,0.612
    c-2.754-1.224-6.427-0.612-8.568,2.448c-33.354,48.96-104.958,57.834-159.425,58.446c-57.222,0.918-102.204-14.076-140.148-55.998
    c-0.918-0.918-2.754-0.306-2.754,1.224c0,0.918,0,2.142,0.306,3.06c-1.53-2.754-6.426-3.672-7.344,0.612
    c-11.934,54.162-22.95,111.69-26.928,169.524c-42.84,17.748-62.424,67.32-54.774,111.689c0.612,3.672,5.202,4.59,7.956,3.366
    l14.688-7.344c-12.852,33.659-16.218,66.401-8.874,103.428c0.918,4.284,6.426,4.896,9.486,2.448
    c5.202-4.591,10.098-8.874,14.688-13.465c2.142,23.257,11.016,54.469,34.272,59.059c17.442,3.672,24.174-8.262,26.928-22.338
    c12.24,20.808,29.988,41.922,51.408,60.282c-10.098,7.649-25.398,22.949-17.748,37.332c7.65,14.688,25.398,8.262,37.944,1.529
    c0,11.322,0,22.645,0,33.966c0,1.837,1.53,4.284,3.672,4.591c15.912,2.447,27.234-3.366,34.884-14.383
    c-0.306,10.71,1.224,22.338,11.016,24.48c5.814,1.224,11.016-2.754,14.688-6.426c6.732-6.732,14.382-17.748,14.994-27.234l0,0
    c16.218,3.979,33.048,5.508,48.96,4.284c4.59,11.934,12.852,28.151,25.092,27.233c13.464-1.224,17.748-15.3,19.89-27.539
    c7.65,5.813,17.748,12.239,26.01,8.262c10.404-4.896,10.404-18.36,9.181-29.07c11.321,2.754,28.151,4.896,31.212-8.262
    c2.754-11.935-4.284-25.704-13.771-33.354c12.24-19.584,21.114-42.228,27.54-65.483c42.229,10.098,48.042-32.742,37.332-67.933
    c0.918,0.612,1.836,1.53,2.754,2.143c3.366,3.978,8.874,0.306,9.181-3.672c1.529-17.442,4.896-34.578,6.119-52.021
    c0.307-6.119,15.912,3.672,19.891,5.508c2.448,0.918,6.426-1.224,6.731-3.978C542.887,258.946,521.467,171.43,475.872,173.878z
    M92.455,418.678c-10.098-13.771-11.934-32.13-11.628-48.654c0-6.12-7.344-7.038-10.404-2.754
    c-15.606,22.032-14.994,22.338-17.748-1.836c-3.06-28.458,6.12-56.61,17.748-82.314c2.142-4.896-2.448-10.098-7.65-7.65
    c-27.846,13.77-23.256-6.732-17.748-28.152c5.814-22.95,21.42-43.758,41.31-56.61c-4.59,73.44,2.754,146.268,34.884,212.058
    C120.301,421.738,108.979,441.322,92.455,418.678z M241.783,523.942c-9.486,12.852-11.934,26.928-29.376,26.01
    c0-12.546,0-25.397,0-37.943c0-3.673-4.284-6.12-7.344-4.284c-6.12,3.978-28.152,18.359-32.436,5.813
    c-3.366-9.18,7.344-22.032,13.158-28.151c0.306-0.307,0.306-0.612,0.306-0.918c17.748,14.993,37.944,28.151,59.058,37.943
    C243.925,522.412,242.701,522.718,241.783,523.942z M263.815,552.4c-2.142,3.06-5.814,8.262-9.792,8.568
    c-7.956,0.611-6.426-26.316-6.12-29.988l0,0c0.306-1.225,0.918-2.448,1.224-3.672c0.306-1.225,0-2.448-0.306-3.061
    c8.568,3.979,17.442,7.038,26.316,9.486C270.241,538.936,267.793,546.28,263.815,552.4z M350.719,556.684
    c-6.427-3.366-10.711-10.71-14.383-17.748c10.71-1.53,21.42-4.284,31.519-8.568c0,0,0,0,0,0.307
    C367.242,538.936,364.794,564.333,350.719,556.684z M433.032,500.074c5.814,16.218-18.666,9.18-26.01,7.038
    c-2.754-0.918-6.426,1.53-6.12,4.59c0.918,6.12,1.224,12.24,0.918,18.36c-0.612,17.748-20.808,1.53-26.316-2.754
    c-0.306-0.307-0.611-0.307-0.611-0.307c2.447-1.224,4.896-2.447,7.038-3.672c16.83-9.792,29.987-23.256,41.004-39.168
    C426.912,489.669,430.891,494.26,433.032,500.074z M445.578,398.482c-1.836,1.224-2.754,3.978-1.836,6.426
    c-9.486,31.519-23.256,60.588-43.452,85.068c-75.888,90.576-212.669-4.896-258.569-78.643
    c-71.604-114.443-38.25-269.279-20.196-393.821c8.262,49.572,95.166,60.282,132.498,62.424
    c59.669,3.366,144.738-9.486,180.54-63.954c22.644,70.686,27.846,147.798,27.846,221.85
    C462.408,288.016,459.96,345.85,445.578,398.482z M507.696,270.88c-3.366-2.448-8.568,0.918-8.262,4.896
    c1.836,18.666-1.53,36.719-3.366,55.08c-3.672-2.754-7.65-4.896-11.934-7.65c-4.591-3.06-9.792,2.754-7.345,7.345
    c12.853,22.949,23.868,78.336-17.441,70.38c9.485-38.862,13.158-79.254,15.605-112.608c2.448-34.883,3.061-71.297,1.53-107.711
    c18.666,6.732,29.376,26.01,38.556,42.84C522.69,237.833,546.559,299.032,507.696,270.88z"/>
    <path d="M417.732,129.508c-0.612-0.306-1.225-0.612-1.836-0.612c0-5.202-7.65-6.732-10.099-2.448
    c-30.294,51.714-79.865,51.102-134.639,41.004c-41.004-7.344-82.314-22.338-117.504-44.676c0-0.306,0.306-0.612,0.306-0.918
    c2.142-4.59-3.978-8.262-6.732-3.978c0,0-0.306,0.306-0.306,0.612c-1.224-0.918-2.448-1.53-3.366-2.448
    c-4.284-3.06-9.18,3.672-5.508,7.038c1.224,1.224,2.754,2.142,4.284,3.366c-28.764,52.02-14.382,120.258,44.982,144.738
    c23.256,9.792,45.9,8.262,64.566-0.918c5.202,18.971,18.666,33.353,31.824,47.124c1.53,1.836,4.896,2.142,6.732,0.918
    c17.441-11.935,26.01-31.519,26.622-52.02c0.306-7.65-11.935-7.65-11.935,0c-0.306,15.3-6.12,29.375-17.441,39.474
    c-11.934-11.628-23.562-23.562-29.376-39.474c10.71-6.732,19.584-16.524,26.01-27.846c15.605,26.622,59.669,32.436,95.166,22.644
    c26.315-7.344,52.938-23.868,64.565-49.572C455.982,185.2,443.742,143.278,417.732,129.508z M207.511,191.32
    c0.612,0,1.224-0.306,1.836-0.306c1.836,1.53,3.978,2.142,6.426,3.06c-1.836,1.224-3.672,2.142-5.508,1.53
    c-2.754-0.612-4.284-2.448-4.896-4.896C205.675,191.32,206.593,191.32,207.511,191.32z M281.869,184.282
    c-4.896,26.622-4.59,53.55-28.764,71.298c-17.136,12.546-39.78,10.404-58.446,3.672c-55.386-20.196-62.73-78.948-44.676-126.99
    c16.218,11.934,34.272,21.726,53.244,29.376c-7.344,12.852-15.606,37.332,3.06,44.37c7.038,2.754,14.994-1.53,20.196-6.12
    c6.426-5.508,13.77-16.524,14.076-25.704c13.77,3.978,27.846,7.038,41.616,9.18C282.175,183.977,281.869,183.977,281.869,184.282z
    M377.034,192.851c-1.224-2.142-2.142-3.979-3.366-6.12c3.061,3.06,6.12,5.814,7.038,10.404c-1.836,0.918-3.366,1.53-5.202,2.142
    C377.34,197.747,378.258,195.298,377.034,192.851z M368.772,180.917c0.306,0.306,0.612,0.612,0.918,1.224
    c-1.224-0.612-2.754-0.612-3.672,0.306c-3.061,1.836-6.732,4.59-5.814,8.568c-1.53-2.142-2.754-4.284-3.978-6.426
    C360.204,183.364,364.488,182.14,368.772,180.917z M431.196,207.232c-9.18,20.502-31.518,32.742-51.714,39.78
    c-35.802,12.24-78.948,7.344-88.434-24.48c3.365-11.322,4.59-23.868,2.754-37.026c1.224,0.306,2.754,0.612,4.283,0.612
    c14.076,1.836,31.824,2.142,48.96-0.306c3.979,7.038,7.345,15.3,13.771,20.502c8.874,7.038,19.89,3.672,29.07-1.224
    c1.836-0.918,2.754-3.06,2.754-4.896c0-5.508-1.225-10.404-4.59-14.688c-2.448-3.366-5.509-5.814-8.263-8.568
    c15.606-7.038,28.152-18.054,33.66-34.578C424.771,163.78,443.13,179.692,431.196,207.232z"/>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    </svg>
);
}
